import {
	FREE_PRODUCTS_PROMO_ALERTS, FREE_PRODUCTS_PROMO_PRODUCTS,
	UPDATE_FREE_PRODUCTS_PROMO_PRODUCTS,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Promotions {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	getPromotionsAlert(userId, options = {}) {
		const { method, endpoint } = FREE_PRODUCTS_PROMO_ALERTS;
		return this.data[method](endpoint(userId), options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPromotionsProducts(userId) {
		const { method, endpoint } = FREE_PRODUCTS_PROMO_PRODUCTS;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateFreeProductsPromotions(options, userId) {
		const { method, endpoint } = UPDATE_FREE_PRODUCTS_PROMO_PRODUCTS;
		return this.data[method](endpoint(userId), options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Promotions;
