<template>
	<div class="row">
		<div class="col text-center">
			<i
				v-for="item in total"
				:key="item"
				:class="isActiveClass(item-1)"
				class="mr-1 pointer"
				@click="$emit('selectItem', item-1)" />
		</div>
	</div>
</template>
<script>
export default {
	name: 'ItemSlided',
	props: {
		total: {
			type: Number,
			required: true,
		},
		current: {
			type: Number,
			default: 0,
		},
		iconActive: {
			type: String,
			default: 'fas fa-circle',
		},
		iconInactive: {
			type: String,
			default: 'far fa-circle',
		},
	},
	methods: {
		isActiveClass(item) {
			if (item === this.current) {
				return this.iconActive;
			}
			return this.iconInactive;
		},
	},
};
</script>
