<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="text-muted text-uppercase font-weight-bold text-center">
					{{ translate(promoCodeName) }}
				</div>
				<p
					class="text-center"
					v-html="description" />
			</div>
		</div>
		<b-row>
			<b-col
				cols="6"
				class="pl-4">
				<div class="align-items-center text-center">
					<img
						v-for="i in progress.order_for_next_price"
						:key="i"
						:src="require(`@/assets/images/themes/${theme}/qualified/${(i <= progress.current_orders) ? 'customer_30_green' : 'customer_30_gray'}.png`)"
						alt="">
					<br>
					<p>{{ translate('enrollments') }}</p>
				</div>
			</b-col>
			<b-col
				cols="6"
				class="pl-4">
				<div class="align-items-center text-center">
					<strong class="text-primary mb-2">{{ progress.current_free_boxes }}</strong>
					<p class="mt-2">
						{{ translate('available_free_something', { something: translate('something_products') }) }}
					</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Promotions from '@/util/Promotions';
import { Promotions as messages, Tooltip } from '@/translations';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'FreeProductsPromoWidget',
	messages: [messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			promotionsInfo: new Promotions(),
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
			loadingSync: false,
		};
	},
	computed: {
		description() {
			try {
				return this.translate(`${this.promoCodeName}_description`);
			} catch (e) {
				return '';
			}
		},
		progress() {
			try {
				return this.data.progress;
			} catch (e) {
				return {};
			}
		},
	},
	methods: {
		selectProducts() {
			this.$refs.productLoyaltyProducts.$emit('selectProducts');
		},
	},
};
</script>
