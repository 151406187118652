<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="text-muted text-uppercase font-weight-bold text-center">
					{{ translate(promoCodeName) }}
				</div>
				<p
					class="text-center"
					v-html="description" />
			</div>
		</div>
		<b-row>
			<b-col
				cols="12"
				class="pl-4">
				<div class="align-items-center text-center">
					<!--<strong class="text-primary mb-2 h5">{{ progress.current_free_boxes }}</strong>-->
					<orders
						v-if="data.orders.length > 0"
						:id="`${promoCodeName}-order-widget`"
						:order-id="0"
						:shown-number="`${progress.current_free_boxes}`"
						:orders="data.orders"
						:show-boxes="false"
						show-package
						show-product-qty />
					<span v-else>
						{{ data.orders.length }}
					</span>
					<p class="mt-2">
						{{ translate('available_free_something', { something: translate('something_products') }) }}
					</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Promotions from '@/util/Promotions';
import { Promotions as messages, Tooltip, Packages } from '@/translations';
import Orders from '@/views/Dashboard/components/widgets/Orders';

export default {
	name: 'FreeProductsPromoWidgetType2',
	components: { Orders },
	messages: [messages, Tooltip, Packages],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			promotionsInfo: new Promotions(),
			loadingSync: false,
		};
	},
	computed: {
		description() {
			try {
				return this.translate(`${this.promoCodeName}_description`);
			} catch (e) {
				return '';
			}
		},
		progress() {
			try {
				return this.data.progress;
			} catch (e) {
				return {};
			}
		},
	},
};
</script>
