<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="text-muted text-uppercase font-weight-bold text-center">
					{{ translate(promoCodeName) }}
				</div>
				<p
					class="text-center"
					v-html="description" />
			</div>
		</div>
		<b-row>
			<b-col>
				<div class="text-center">
					<div class="text-muted">
						<span class="font-weight-bold">{{ translate('ambassador_plus') }}</span><br>
						<span>{{ progress.purchases.ambassador_plus }}</span>
					</div>
				</div>
			</b-col>
			<b-col>
				<div class="text-center">
					<div class="text-muted">
						<span class="font-weight-bold">{{ translate('ambassador') }}</span><br>
						<span>{{ progress.purchases.ambassador }}</span>
					</div>
				</div>
			</b-col>
		</b-row>
		<div
			v-if="progress.free.total > 0"
			class="mt-3 align-items-center text-center">
			<strong class="text-primary mb-2 h5">{{ translate('x_of_y_shipped', { shipped: progress.free.shipped, total: progress.free.total }) }}&nbsp;</strong>
			<i
				v-b-tooltip.hover.right
				:title="translate('fathers_day_promo_2023_tooltip', { qty: progress.free.total, shipped: progress.free.shipped, pending: progress.free.total - progress.free.shipped })"
				class="fas fa-info-circle" />
			<p>
				{{ translate('free_tuun_swarovski') }}
			</p>
		</div>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Promotions from '@/util/Promotions';
import { Promotions as messages, Packages } from '@/translations';

export default {
	name: 'FreeProductsPromoWidgetType3',
	messages: [messages, Packages],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			promotionsInfo: new Promotions(),
			loadingSync: false,
		};
	},
	computed: {
		description() {
			try {
				return this.translate(`${this.promoCodeName}_description`);
			} catch (e) {
				return '';
			}
		},
		progress() {
			try {
				return this.data.progress;
			} catch (e) {
				return {
					purchases: {
						ambassador_plus: 0,
						ambassador: 0,
					},
					free: {
						shipped: 0,
						total: 0,
					},
				};
			}
		},
	},
};
</script>
