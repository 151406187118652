<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="text-muted text-uppercase font-weight-bold text-center">
					{{ translate(promoCodeName) }}
					<a
						v-if="link"
						v-b-tooltip.hover
						:href="link"
						target="_blank"
						:title="translate('view_details')"
						class="text-decoration-none text-muted link-primary">
						<i class="fas fa-info-circle" />
					</a>
				</div>
			</div>
		</div>
		<div class="w-100">
			<div class="d-flex flex-column align-items-center my-2">
				<h1 class="text-blue my-0">
					{{ data.total_points }}
				</h1>
			</div>
		</div>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import { Promotions as messages, Tooltip } from '@/translations';

export default {
	name: 'TripPoints',
	messages: [messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			loadingSync: false,
		};
	},
	computed: {
		link() {
			try {
				return this.data.link;
			} catch (e) {
				return false;
			}
		},
	},
};
</script>

<style scoped>
.text-blue {
	color: #63C2DE !important;
}
</style>
