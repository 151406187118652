<template>
	<div>
		<custom-modal
			:show="showFreeProductsModal"
			:pre-title="translate('free_products_promo_pre_title')"
			:title="translate('free_products_promo_title')"
			ref-name="freeProductModal"
			:confirm-text="translate('free_products_promo_accept')"
			:cancel-text="translate('free_products_promo_cancel')"
			:show-close-button="false"
			@confirm="chooseProducts"
			@close="closeModal">
			<p class="text-center px-2">
				{{ translate('free_products_promo_body') }}
			</p>
		</custom-modal>
		<free-products-modal
			ref="promotionProductsModal"
			:sections-import="selectableFreeProducts"
			:selectable-products.sync="selectableProducts"
			:ok-title="translate('confirm')"
			:cancel-title="translate('cancel')"
			@close="closeModal"
			@confirm="saveProducts" />
	</div>
</template>
<script>
import { admin } from '@/settings/Roles';
import { Promotions as messages, Tooltip } from '@/translations';
import Alert from '@/util/Alert';
import Promotions from '@/util/Promotions';
import FreeProductsModal from '@/components/FreeProductsModal/index.vue';
import CustomModal from '@/components/CustomModal';

export default {
	name: 'PromotionsAlert',
	components: { CustomModal, FreeProductsModal },
	messages: [messages, Tooltip],
	props: {
		promosInfo: {
			type: Object,
			default: () => ({}),
		},
		fromWidget: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			admin,
			theme: process.env.VUE_APP_THEME,
			alert: new Alert(),
			promotions: new Promotions(),
			savePromotions: new Promotions(),
			selectableProducts: {},
			showFreeProductsModal: false,
		};
	},
	computed: {
		data() {
			try {
				return this.promotions.data.response.data.response;
			} catch (e) {
				return '';
			}
		},
		selectableFreeProducts() {
			try {
				const validPromos = this.data.promotions.filter((promo) => Object.keys(promo.selectable_products).length > 0);
				return validPromos.map((promo) => promo.selectable_products.selectable_products);
			} catch (e) {
				return [];
			}
		},
	},
	mounted() {
		if (!this.admin.includes(this.$user.details().type)) {
			this.showAlert();
		} else {
			this.$emit('done');
		}
	},
	methods: {
		showAlert() {
			const promotionArray = Object.values(this.promosInfo);
			const promosToShow = promotionArray.filter((promo) => promo.pending_choose);
			const fromWidget = promotionArray.length > 0 && this.fromWidget;
			if (promosToShow.length > 0 || fromWidget) {
				this.promotions.getPromotionsProducts(this.$user.details().id).then(() => {
					if (this.selectableFreeProducts.length > 0) {
						if (!fromWidget) {
							this.showFreeProductsModal = true;
						} else {
							this.$refs.promotionProductsModal.open();
						}
					}
				});
			} else {
				this.$emit('done');
			}
		},
		saveProducts() {
			const options = {
				promotions: this.selectableProducts,
			};
			this.savePromotions.updateFreeProductsPromotions(options, this.$user.details().id).then(() => {
				this.alert.toast('success', this.translate('products_updated'));
			}).finally(() => {
				this.closeModal();
			});
		},
		chooseProducts() {
			this.$refs.promotionProductsModal.open();
		},
		closeModal() {
			this.showFreeProductsModal = false;
			this.$emit('done');
		},
	},
};
</script>
