<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 p-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="text-muted text-uppercase font-weight-bold">
					{{ translate('product_loyalty_program') }}
					<a
						v-b-tooltip.hover
						:href="infoLink"
						target="_blank"
						:title="translate('view_details')"
						class="text-decoration-none link-primary">
						<i class="fas fa-info-circle" />
					</a>
				</div>
				<p
					class="text-center"
					v-html="description" />
			</div>
			<b-progress
				:max="data.goal_months"
				:variant="consecutiveMonths === totalMonths ? 'lime' : 'primary'"
				height="30px"
				class="mt-auto">
				<b-progress-bar :value="consecutiveMonths">
					<strong style="width: 90%; font-size: 1.7em; position: absolute">
						<span class="text-dark bg-light rounded px-3">
							{{ `${consecutiveMonths} / ${totalMonths} ${translate('months')}` }}
						</span>
					</strong>
				</b-progress-bar>
			</b-progress>
		</div>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Promotions from '@/util/Promotions';
import { admin } from '@/settings/Roles';
import { Promotions as messages, Tooltip } from '@/translations';
import { PRODUCT_LOYALTY } from '@/settings/Promotions';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'ProductLoyaltyWidget',
	messages: [messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		routeUserId: {
			type: Number,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			promotionsInfo: new Promotions(),
			infoLink: PRODUCT_LOYALTY,
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
			loadingSync: false,
		};
	},
	computed: {
		currentUserId() {
			let userId = this.$user.details().id;
			if (admin.includes(this.$user.details().type)) {
				userId = this.routeUserId;
			}
			return userId;
		},
		consecutiveMonths() {
			try {
				return this.data.consecutive_months;
			} catch (e) {
				return 0;
			}
		},
		totalMonths() {
			try {
				return this.data.goal_months;
			} catch (e) {
				return 0;
			}
		},
		description() {
			try {
				if (this.data.goal_months === 6) {
					return this.translate('product_loyalty_description_6_months');
				}
				if (this.data.goal_months === 3) {
					return this.translate('product_loyalty_description_3_months');
				}
				return '';
			} catch (e) {
				return '';
			}
		},
	},
};
</script>
